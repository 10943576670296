'use client';

import useStore from '@/stores/useStore';
import IconArrowXxlButton from './IconArrowXxlButton';
import IconGridBreakup from './IconGridBreakup';
import ProductTourModal from './Modals/ProductTourModal';

export interface HomeTeamsTourProps {
  title: string;
  description: string;
  image: React.ReactNode;
  desktopFeatureIcon: React.ReactNode;
  cta: {
    title: string;
    href: string;
  };
  features: {
    icon: React.ReactNode;
    title: string;
    description: string;
  }[];
}

const HomeTeamsTour = ({ title, description, image, desktopFeatureIcon, cta, features = [] }: HomeTeamsTourProps) => {
  const { isProductTourModalOpen, setProductTourModalOpen } = useStore();
  const desktopFeatureGrid = (
    <div className="hidden md:block text-blue-800">
      <div className="flex gap-3 justify-center items-center">
        {features.map((feature, index) => (
          <div key={`home-teams-tour-feature-${feature.title?.replace(/\s/g, '-') ?? index}`} className="flex justify-center items-center gap-1">
            {desktopFeatureIcon}
            <p className="text-12/16">{feature.title}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const mobileFeatureGrid = (
    <div className="flex flex-col gap-3 md:hidden mt-2">
      {features.map((feature, index) => (
        <div key={`home-teams-tour-feature-${feature.title?.replace(/\s/g, '-') ?? index}`} className="rounded-10 p-3 bg-blue-100">
          <div className="flex flex-col gap-3">
            <div className="flex-shrink-0">{feature.icon}</div>
            <div className="flex flex-col gap-1">
              <p className="text-20/28 font-500 tracking-tight">{feature.title}</p>
              <p className="text-17/24 text-blue-800">{feature.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const teamsImageCta = (
    <div className="hidden md:block relative">
      {image}
      <div className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
        <button
          onClick={() => setProductTourModalOpen(true)}
          className="block button button-size-xxl button-primary-violet-xxl"
        >
          <div className="flex items-center gap-1">
            <span className="block">{cta.title}</span>
            <IconArrowXxlButton className="block" />
          </div>
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <div className="hidden md:block md:h-[21vw] relative">
        <IconGridBreakup className="absolute left-0 right-0 bottom-[-1px] w-full h-auto text-blue-300" />
      </div>
      <div className="px-container-mobile md:px-container-desktop py-6 md:pb-0 md:pt-3 bg-blue-300 text-blue-900">
        <div className="mx-auto max-w-grid flex flex-col gap-2 md:gap-10">
          <div className="grid md:grid-cols-12">
            <div className="md:col-span-10 md:col-start-2 flex flex-col gap-2 md:text-center">
              <h2 className="text-36/42 md:text-43/52 font-500 tracking-tight">{title}</h2>
              <p className="text-20/28 text-blue-800">{description}</p>
              {desktopFeatureGrid}
              {mobileFeatureGrid}
            </div>
          </div>

          {teamsImageCta}
        </div>
      </div>
      {isProductTourModalOpen && <ProductTourModal />}
    </div>
  );
};

export default HomeTeamsTour;
