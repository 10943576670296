'use client';

import animationData from '@/animations/ai-leaders.json';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { FC, useEffect, useRef } from 'react';

export interface AnimationAiLeadersProps {
  playing?: boolean;
}

const AnimationAiLeaders: FC<AnimationAiLeadersProps> = ({ playing }) => {
  const animationRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    if (!animationRef.current) return;

    if (playing) {
      animationRef.current.play();
    } else {
      animationRef.current.pause();
    }
  }, [playing]);

  return (
    <div className="aspect-square">
      <Lottie animationData={animationData} autoplay={false} lottieRef={animationRef} />
    </div>
  );
};

export default AnimationAiLeaders;
