'use client';

import ease from '@/constants/ease';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { useState } from 'react';
import { useIntervalWhen } from 'rooks';

const CAROUSEL_INTERVAL = 3000;
const CAROUSEL_SLIDES = 4;

export interface HomeCustomerSuccessProps {
  description: string;
  customerImages: string[];
  tiles: {
    theme: 'yellow' | 'salmon' | 'pink' | 'blue' | 'green';
    eyebrow?: string;
    title: string;
    description: string;
  }[];
}

const HomeCustomerAvatars = ({ customerImages = [] }: { customerImages: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useIntervalWhen(
    () => {
      if (currentIndex >= customerImages.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    },
    CAROUSEL_INTERVAL,
    true,
  );

  const currentSlides: string[] = [];

  for (let i = 0; i < CAROUSEL_SLIDES; i++) {
    currentSlides.push(customerImages[(currentIndex + i) % customerImages.length]);
  }

  return (
    <div className="relative h-5 md:h-[60px] w-[135px] md:w-[190px]">
      <AnimatePresence>
        {customerImages.map((image, index) => {
          const active = currentSlides.includes(image);
          const currentSubsetIndex = currentSlides.indexOf(image);
          const indexOfImages = customerImages.indexOf(image);

          if (!active) return null;

          return (
            <motion.div
              key={indexOfImages}
              className="absolute top-0 left-0"
              initial={{ opacity: 0, x: `${CAROUSEL_SLIDES * 75}%` }}
              animate={{ opacity: 1, x: `${currentSubsetIndex * 75}%` }}
              exit={{ opacity: 0, x: '-100%' }}
              style={{
                zIndex: currentSubsetIndex,
              }}
              transition={{ duration: 400 / 1000, ease: ease.appear }}
            >
              <div className="rounded-full w-5 h-5 md:w-[60px] md:h-[60px] overflow-hidden border-2 border-solid border-white">
                <Image src={image} width={80} height={80} alt="" />
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
};

const HomeCustomerSuccess = ({ description, customerImages = [], tiles = [] }: HomeCustomerSuccessProps) => {
  const headline = (
    <h2 className="font-500 tracking-tight text-30/36 md:text-52/62">
      <span>Keep your </span>
      <br className="hidden md:block" />
      <div className="inline-block mx-1 relative top-[8px] md:hidden">
        <HomeCustomerAvatars customerImages={customerImages} />
      </div>
      <span>customers </span>
      <div className="hidden mx-1 relative top-[8px] md:inline-block">
        <HomeCustomerAvatars customerImages={customerImages} />
      </div>
      <span> at the </span>
      <br className="hidden md:block" />
      <span className="md:font-serif">center of your business.</span>
    </h2>
  );

  const header = (
    <div className="flex flex-col gap-3 text-center items-center justify-center">
      {headline}
      <p className="text-17/24 md:text-20/28 text-gray-700 max-w-80 text-pretty">{description}</p>
    </div>
  );

  const tileGrid = (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-2">
      {tiles.map((tile, index) => (
        <div
          key={`home-cs-tile-${tile.title?.replace(/\s/g, '-') ?? index}`}
          className={cx('flex flex-col gap-2 items-center justify-center rounded-10 px-3 py-5 text-center', {
            'bg-yellow-200 text-yellow-800': tile.theme === 'yellow',
            'bg-salmon-200 text-salmon-800': tile.theme === 'salmon',
            'bg-pink-200 text-pink-800': tile.theme === 'pink',
            'bg-green-200 text-green-800': tile.theme === 'green',
            'bg-blue-200 text-blue-800': tile.theme === 'blue',
          })}
        >
          {tile.eyebrow && <div className="text-15/22">{tile.eyebrow}</div>}
          <div className="text-80/80 tracking-tight font-serif">{tile.title}</div>
          <div className="text-15/22">{tile.description}</div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="my-16 md:my-20 lg:my-25 px-container-mobile md:px-container-desktop">
      <div className="mx-auto max-w-grid">
        <div className="grid md:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop">
          <div className="md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
            <div className="flex flex-col gap-8 md:gap-9">
              {header}
              {tileGrid}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCustomerSuccess;
