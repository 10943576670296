'use client';

import ease from '@/constants/ease';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import Image from 'next/image';
import { FC, useState } from 'react';
import Link from './Link';

export interface HomeFrontIntelligenceProps {
  title: string;
  description: string;
  cta: {
    title: string;
    href: string;
  };
  hideCta: boolean;

  features: {
    icon: React.ReactNode;
    title: string;
    description: string;
    list?: string[];
    tag?: string;
    image: React.ReactNode;
  }[];
}

const HomeFrontIntelligence: FC<HomeFrontIntelligenceProps> = ({ title, description, cta, hideCta, features = [] }) => {
  const [activeFeatureIndex, setFeatureIndex] = useState(0);
  const activeFeature = features[activeFeatureIndex];

  const [mobileSliderRef] = useKeenSlider({
    loop: false,
    drag: true,
    slides: {
      origin: 'auto',
      perView: 1.2,
      spacing: 16,
    },
    range: {
      align: true,
    },
    slideChanged: (s) => {
      setFeatureIndex(s.track.details.abs);
    },
  });

  const mobileNavSlider = (
    <div className="md:hidden">
      <div ref={mobileSliderRef} className="keen-slider overflow-visible">
        {features.map((feature, index) => (
          <div
            key={`mobile-feature-${feature.title?.replace(/\s/g, '-') ?? index}`}
            className="keen-slider__slide rounded-20 border border-solid border-violet-300 border-opacity-40 p-3 flex flex-col gap-3"
          >
            <div className="flex flex-col gap-1 items-start">
              <p className="text-20/28 font-500 tracking-tight">{feature.title}</p>
              {feature.tag && (
                <span className="bg-citron-500 text-citron-900 block rounded-4 px-half text-10/20 h-[20px] uppercase font-mono font-800">
                  {feature.tag}
                </span>
              )}
            </div>
            <div className="text-17/20">{feature.description}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const desktopNav = (
    <div className="hidden md:flex flex-col gap-2">
      {features.map((feature, index) => (
        <div key={`desktop-feature-${feature.title?.replace(/\s/g, '-') ?? index}`} className="keen-slider__slide">
          <div
            className={cx(
              'p-3 flex flex-col gap-2 rounded-20 relative transition-colors duration-200 ease-in-out-cubic',
              {
                'bg-violet-800': index === activeFeatureIndex,
                'border-transparent': index !== activeFeatureIndex,
              },
            )}
          >
            <div className="flex flex-col gap-1">
              <div className="flex gap-1 items-center">
                <button
                  onClick={() => setFeatureIndex(index)}
                  className={`block link-cover text-20/28 font-500 tracking-tight ${index === activeFeatureIndex ? "text-violet-300" : "text-violet-100"}`}
                >
                  {feature.title}
                </button>
                {feature.tag && (
                  <span className="bg-citron-500 text-citron-900 block rounded-4 px-half text-10/20 h-[20px] uppercase font-mono font-800">
                    {feature.tag}
                  </span>
                )}
              </div>
            </div>
            {index === activeFeatureIndex && (
              <motion.div
                className="text-17/20 text-violet-300"
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 400 / 1000,
                  ease: ease.appear,
                }}
              >
                {feature.description}
                {feature.list &&
                  <ul className='list-disc ps-2 pt-2'>
                    {feature.list.map(item =>
                      <li className="marker:text-violet-600 mb-[10px]" key={item}>{item}</li>
                    )}
                  </ul>
                }
              </motion.div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const gradient = (
    <div className="rounded-full bg-violet-600 blur-[250px] w-[95vw] h-[50vw] md:h-[20vw] absolute left-[5vw] top-0 translate-y-[-50%]" />
  );

  return (
    <div className="bg-violet-900 text-white px-container-mobile md:px-container-desktop py-6 md:py-20 relative overflow-hidden">
      {gradient}

      <div className="mx-auto max-w-grid">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-y-9 relative z-1">
          <div className="md:col-span-6 lg:col-span-5 flex flex-col gap-6">
            <div className="flex flex-col gap-3 items-start">
              <h2 className="text-30/36 md:text-40/42 font-500 tracking-tight">{title}</h2>
              <p className="text-17/24 md:text-18/28 max-w-62">{description}</p>
              {!hideCta && (
                <Link href={cta.href} className="block button button-size-medium-ai button-sparkle-violet">
                  <span className="button-sparkle-gradient" />
                  <span className="button-sparkle-spark" />
                  <span className="button-sparkle-bg" />
                  <span className="button-sparkle-glow" />
                  <span className="button-sparkle-text">
                    <span className="flex gap-1 items-center">
                      <Image src="/images/icon-sparkle-ai.svg" width={20} height={20} className="block" alt="" />
                      <span className="block">{cta.title}</span>
                    </span>
                  </span>
                </Link>
              )}
            </div>

            {desktopNav}
          </div>
          <div className="md:col-span-6 lg:col-span-6 lg:col-start-7 flex flex-col gap-4">
            <div className="bg-violet-200 rounded-10 md:rounded-30 overflow-hidden relative">
              <AnimatePresence>
                {activeFeature && (
                  <motion.div
                    key={activeFeatureIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                    transition={{
                      duration: 400 / 1000,
                      ease: ease.appear,
                    }}
                  >
                    {activeFeature.image}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {mobileNavSlider}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFrontIntelligence;
